import Profile from "./PortfolioContainer/Home/Profile";
import React from "react";
import './App.css';

function App() {
  return (
    <div className="App">
        <Profile />
    </div>
  );
}

export default App;
